/** @jsxImportSource @emotion/react */
// eslint-disable-next-line no-unused-vars
import log from "cslog";
import React from "react";
import { css } from '@emotion/react'
import { decodeStyle, getHTMLContent, parseStyle, SlateViewer, useAutoFitNew } from "../../../junctionAbs";
import ElementWrapper from "./wrappers/ElementWrapper";

function HtmlText({ id, db, gs, dydb, ctx }) {
	const data = db[id];

	const ps = parseStyle(data, gs, data.cstate);

	let input = ps.input;

	let styles_decoded = decodeStyle(ps.style, gs);

	const html = getHTMLContent(dydb, input.html);


	let { ref, fontSize } = useAutoFitNew(
		[JSON.stringify(html)],
		input.autofitText?.enable,
		input.autofitText?.params?.min,
		input.autofitText?.params?.max,
	);

	if (!input.autofitText?.enable) {
		fontSize = input.fontSize;
	}

	return (
		<ElementWrapper id={id}>
			<div
				ref={ref}
				style={{
					columnWidth: "auto",
					columnCount: input.columnCount,
					...decodeStyle(ps.fixStyle, gs),
					// ...decodeStyle(ps.style, gs),
					...styles_decoded,
					// "&:hover": decodeStyle(ps.hover, gs),
					textAlign: input.textAlign,
					width: '100%',
					minHeight: '100%', // to limit inside box
					fontSize: `${fontSize}px`,
				}}
			>
				<div
					// className={styles.fit_wrapper}
					style={{
						// border: "1px dotted purple",
						width: 'auto',
						// fontSize: `${final_fs}px`,
					}}
					css={css`
							  .qslate p,
							  .qslate li,
							  .qslate blockquote {
								  line-height: ${input.lineHeight * 100}%;
								  letter-spacing: ${input.letterSpacing.value};
							  }
							  .qslate h1, .qslate h2, .qslate h3, .qslate h4, .qslate h5, .qslate h6 { 
								  font-family: ${styles_decoded?.fontFamilyHeadings || 'inherit'};
							  }
							`}
				>
					<SlateViewer
						id={id}
						input={{
							...input,
							html: html
						}}
						styles={styles_decoded}
						dydb={dydb}
					/>
				</div>
			</div>
		</ElementWrapper>
	);
}

export default HtmlText;
