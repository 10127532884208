export const EDITOR_VERSION = 8; // for internal purpose
export const EDITOR_VERSION_TEXT = "2.1.1" // for show and releases

export const ENABLE_AI_BOT = false
export const ENABLE_AI_WRITER = false

export const ENABLE_PRESETS = true;

export const ENABLE_CUSTOM_DOMAIN = true

export const SHOW_FILLABLE_MODAL = false

export const ENABLE_PREVIEW = true

export const INCH_TO_PIXEL = 96;  //DO NOT CHANGE
export const DEFAULT_PADDING_CORRECTION_IN_MARGIN = 10;
