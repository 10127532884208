/** @jsxImportSource @emotion/react */
// eslint-disable-next-line no-unused-vars
import React from "react";
import ElementWrapper from "./wrappers/ElementWrapper";
// import { parseStyle, decodeStyle } from "../parser/styleparser";
import classNames from "classnames";
import ContentEditable from "react-contenteditable";
import { decodeStyle, parseStyle, useAutoFitNew } from "../../../junctionAbs";
import log from "cslog";
// import { getContent } from "../../../../dynamicdata/parser";
import { getContent } from "../../../junctionAbs";

const isString = (value) => {
	return typeof value === "string" || value instanceof String;
};

function Header({ id, db, gs, dydb, ctx }) {
	const data = db[id];

	log.d(gs, "GS in Header rendering");

	const ps = parseStyle(data, gs, data.cstate);

	let input = ps.input;

	// let content = input?.content;
	const content = getContent(dydb, input?.content);

	let { ref, fontSize } = useAutoFitNew(
		[content],
		input.autofitText?.enable,
		input.autofitText?.params?.min,
		input.autofitText?.params?.max,
	);

	if (!input.autofitText?.enable) {
		fontSize = input.headingTag.value
	}

	return (
		<ElementWrapper id={id}>
			<div
				ref={ref}
				style={{
					width: "100%",
					height: "100%",
					...decodeStyle(ps.fixStyle, gs),
					...decodeStyle(ps.style, gs),
					textAlign: input.textAlign,
					textTransform: input.uppercase ? "uppercase" : "none",
					fontWeight: input.bold ? "bold" : "normal",
					fontSize: fontSize,
					letterSpacing: input.letterSpacing?.value || "normal",
					outline: "none",
					transition: "none",
					// --------------
					// for vertical align
					display: "flex",
					flexDirection: "column",
					justifyContent: input.verticalAlign
				}}
			>
				<ContentEditable
					className={classNames(ps.classes)}
					html={content ? content : ""}
					disabled={true}
					style={{
						outline: "none"
					}}
				/>
			</div>
		</ElementWrapper>
	);
}

export default Header;
