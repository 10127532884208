import React, { useEffect, useState, useRef } from "react";
import { useRecoilState } from "recoil";
import { elementState } from "../db/elementDb";
import produce from "immer";
import log from "cslog";


export function getVerticalAlignClass(align) {
	switch (align) {
		case "start":
			return "q_align_top";
		case "center":
			return "q_align_middle";
		case "end":
			return "q_align_bottom";
		default:
			return "q_align_top";
	}
}

export function useBox(id, pid) {
	const [edata, setEdata] = useRecoilState(elementState(pid));

	const data = edata["childs"][id];
	const setData = (new_data) => {
		setEdata({
			...edata,
			childs: produce(edata["childs"], (draft) => {
				draft[id] = new_data;
			}),
		});
	};

	return {
		box: data,
		setBox: setData,
	};
}



export function useAutoFitNew(deps = [], enable = true, min = 1, max = 120) {
	const {
		containerRef: ref,
		fontSize,
		fitText,
	} = useAutoFitText(16, 0.5, min, max);

	useEffect(() => {
		if (enable) {
			fitText();
		}
	}, []);

	useEffect(() => {
		if (enable) {
			fitText();
		}
	}, deps);

	return {
		ref,
		fontSize,
	}
}


export function useAutoFitFull(autofit, box, setBox, data, setData, contentEditableRef, edit, ps_fontSize, ps_lineHeight, step = 1,) {
	const {
		containerRef: ref,
		fontSize,
		fitText,
	} = useAutoFitText(ps_fontSize, step);

	const final_fs = autofit === "text" ? fontSize : ps_fontSize;

	const setHeight = (new_height) => {
		log.d(new_height, "setting new height");
		setBox({
			...box,
			height: new_height,
		});
	};

	const setFS = (new_fs) => {
		log.d(new_fs, "new fontsize");
		setData({
			...data,
			input: {
				...data.input,
				fontSize: new_fs,
			},
		});
	};

	const updateHeight = () => {
		if (contentEditableRef.current) {
			const diff = box.height - contentEditableRef.current.offsetHeight;
			if (Math.abs(diff) > 5) {
				setHeight(contentEditableRef.current.offsetHeight);
			}
		}
	};

	useEffect(() => {
		log.p("box width changed");
		if (autofit === "box") {
			updateHeight();
		}
	}, [box.width, autofit]);

	useEffect(() => {
		if (autofit === "text") {
			fitText();
		}
	}, [box.height, autofit]);

	useEffect(() => {
		if (autofit === "box") {
			updateHeight();
		}
	}, [ps_fontSize, ps_lineHeight, autofit]);

	useEffect(() => {
		if (autofit === "text" && !edit) {
			setFS(fontSize);
		}
	}, [fontSize]);

	function onTextChange() {
		if (autofit === "box") {
			updateHeight();
		} else if (autofit === "text") {
			fitText();
		}
	}

	return {
		ref, final_fs, onTextChange
	}
}



export function useAutoFitText(initialFontSize = 16, step = 0.5, min = 1, max = 120) {
	const containerRef = useRef(null);
	const [fontSize, setFontSize] = useState(initialFontSize);

	const fitText = () => {
		log.p("Fitting text");
		const container = containerRef.current;
		if (!container) return;
		const parent = container.parentElement;
		if (!parent) return;

		let currentSize = initialFontSize;
		container.style.fontSize = `${currentSize}px`;
		log.d(currentSize, "container fs set to")

		const fitSize = (min, max) => {
			if (max - min < step) return min;

			const mid = (min + max) / 2;
			container.style.fontSize = `${mid}px`;
			log.d(mid, "container fs set to")

			// log.d(mid, "Mid fs")

			if (
				container.scrollHeight <= parent.clientHeight &&
				container.scrollWidth <= parent.clientWidth
			) {
				log.p("increasing")
				return fitSize(mid, max);
			} else {
				log.p("decreasing")
				return fitSize(min, mid);
			}
		};

		const optimalSize = fitSize(min, max);
		setFontSize(optimalSize);
	};

	return { containerRef, fontSize, fitText };
}

export function useAutoFitText2(initialFontSize = 16, step = 0.5) {
	const containerRef = useRef(null);
	const [fontSize, setFontSize] = useState(initialFontSize);

	const fitText = () => {
		const container = containerRef.current;
		if (!container) return;
		const parent = container.parentElement;
		if (!parent) return;

		let currentSize = initialFontSize;
		container.style.fontSize = `${currentSize}px`;

		while (
			container.scrollHeight <= parent.clientHeight &&
			container.scrollWidth <= parent.clientWidth &&
			currentSize < 100
		) {
			currentSize += step;
			container.style.fontSize = `${currentSize}px`;
		}

		while (
			(container.scrollHeight > parent.clientHeight ||
				container.scrollWidth > parent.clientWidth) &&
			currentSize > 0
		) {
			currentSize -= step;
			container.style.fontSize = `${currentSize}px`;
		}

		setFontSize(currentSize);
	};

	return { containerRef, fontSize, fitText };
}

export function usePanSupport(touch_sensitivity = -0.001) {
	const panDivRef = useRef(null);
	const [scale, setScale] = useState(1);
	const [panning, setPanning] = useState(false);
	const [panStart, setPanStart] = useState({ x: 0, y: 0 });
	const [panOffset, setPanOffset] = useState({ x: 0, y: 0 });

	useEffect(() => {
		const container = panDivRef.current;
		let lastTouchDistance = 0;

		const handleWheel = (e) => {
			e.preventDefault();
			const delta = e.deltaY * -0.01;
			// const delta = e.deltaY * sensitivity;
			const newScale = Math.min(Math.max(scale + delta, 1), 5);
			setScale(newScale);
		};

		const handleTouchStart = (e) => {
			if (e.touches.length === 2) {
				lastTouchDistance = getTouchDistance(e.touches);
			} else if (e.touches.length === 1) {
				setPanning(true);
				setPanStart({
					x: e.touches[0].clientX,
					y: e.touches[0].clientY,
				});
			}
		};

		const handleTouchMove = (e) => {
			e.preventDefault();
			if (e.touches.length === 2) {
				const touchDistance = getTouchDistance(e.touches);
				// const delta = (touchDistance - lastTouchDistance) * 0.01;
				const delta =
					(touchDistance - lastTouchDistance) *
					-1 *
					touch_sensitivity;
				lastTouchDistance = touchDistance;
				const newScale = Math.min(Math.max(scale + delta, 1), 5);
				setScale(newScale);
			} else if (e.touches.length === 1 && panning) {
				const deltaX = e.touches[0].clientX - panStart.x;
				const deltaY = e.touches[0].clientY - panStart.y;
				setPanOffset({
					x: panOffset.x + deltaX,
					y: panOffset.y + deltaY,
				});
				setPanStart({
					x: e.touches[0].clientX,
					y: e.touches[0].clientY,
				});
			}
		};

		const handleTouchEnd = () => {
			setPanning(false);
		};

		const getTouchDistance = (touches) => {
			return Math.hypot(
				touches[0].clientX - touches[1].clientX,
				touches[0].clientY - touches[1].clientY
			);
		};

		container.addEventListener("wheel", handleWheel);
		container.addEventListener("touchstart", handleTouchStart);
		container.addEventListener("touchmove", handleTouchMove);
		container.addEventListener("touchend", handleTouchEnd);

		return () => {
			container.removeEventListener("wheel", handleWheel);
			container.removeEventListener("touchstart", handleTouchStart);
			container.removeEventListener("touchmove", handleTouchMove);
			container.removeEventListener("touchend", handleTouchEnd);
		};
	}, [scale, panning, panStart, panOffset]);

	return { panDivRef, pan_scale: scale, panOffset };
}
