/** @jsxImportSource @emotion/react */
// eslint-disable-next-line no-unused-vars
import log from "cslog";
import React, { createRef, Fragment, useLayoutEffect } from "react";
import { decodeAttribute, decodeStyle, parseStyle } from "../../../junctionAbs";
import ElementWrapper from "./wrappers/ElementWrapper";

// TODO: work on responsive style
function Shape({ id, db, gs }) {
	const data = db[id];

	const ps = parseStyle(data, gs, data.cstate);

	let input = ps.input;

	const shapeData = input.shapeData || {};

	const fill = input.fill || {};
	const fill_decoded = decodeAttribute(input.fill, gs);

	const stroke = ps.style.border;

	const shadow = ps.style.boxShadow?.type === "solid" ? ps.style.boxShadow?.params : null;

	let gradient_colors = null;
	if (fill.type === "gradient") {
		if (fill.params?.numCol === 3) {
			gradient_colors = <Fragment>
				<stop
					offset="5%"
					stop-color={fill.params.col1}
				/>
				<stop
					offset="50%"
					stop-color={fill.params.col2}
				/>
				<stop
					offset="95%"
					stop-color={fill.params.col3}
				/>
			</Fragment>
		} else {
			gradient_colors = <Fragment>
				<stop
					offset="5%"
					stop-color={fill.params.col1}
				/>
				<stop
					offset="95%"
					stop-color={fill.params.col2}
				/>
			</Fragment>
		}
	}


	return (
		<ElementWrapper id={id}>
			<div
				style={{
					width: "100%",
					height: "100%",
					backdropFilter: ps.style?.backgroundFilter?.value || "none",
				}}
			>
				<svg
					version="1.1"
					id="Layer_1"
					xmlns="http://www.w3.org/2000/svg"
					// viewBox="0 0 346.6 261.4"
					viewBox={`0 0 ${shapeData.w} ${shapeData.h}`}
					style={{
						width: "100%",
						height: "100%",
						// fill: input.fill,
						fill:
							fill.type !== "gradient"
								? fill_decoded
								: `url(#pattern_${id})`,
						...(stroke?.type === "solid" ? {
							stroke: stroke?.params?.color,
							strokeWidth: stroke?.params?.width,
							paintOrder: "fill"
						} : {}),
						opacity: input.opacity,
						filter: shadow ? `drop-shadow(${shadow.hlength}px ${shadow.vlength}px ${shadow.blur_radius}px ${shadow.color})` : null,
					}}
					preserveAspectRatio="none"
				>
					<title>Asset 97</title>
					<defs
						style={{
							width: "100%",
							height: "100%",
						}}
					>
						{fill.type === "gradient" && fill.params?.fd === "ellipse at center" && <radialGradient id={`pattern_${id}`}>
							{gradient_colors}
						</radialGradient>}
						{fill.type === "gradient" && (
							<linearGradient id={`pattern_${id}`}>
								{gradient_colors}
							</linearGradient>
						)}
					</defs>
					<path
						d={shapeData.d}
						// strokeMiterlimit="10"
						strokeWidth={input.strokeWidth}
						fillRule="nonzero"
					// fill="red"
					/>
				</svg>
			</div>
		</ElementWrapper>
	);
}

export default Shape;
