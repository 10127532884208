/** @jsxImportSource @emotion/react */
import styles from "./styles/SimpleOption.module.css"
import React, { Fragment, useState, useEffect, useRef } from "react";
import { SketchPicker, SliderPicker, SwatchesPicker } from "react-color";
import { useRecoilValue } from "recoil";
import { themeColorState } from "../db/gstyleDb";
import produce from "immer";

import { Range } from "react-range";

import log from "cslog";
import { startCase } from "lodash";
import {
	Button,
	Dropdown,
	Form,
	Icon,
	Input,
	Label,
	Popup,
	TextArea,
} from "semantic-ui-react";
// import DynamicDataOption from "../dynamicdata/DynamicDataOption";
import DynamicDataOption from "../dynamic/DynamicDataOption";
// import DynamicDataView from "../dynamicdata/DynamicDataView";
import DynamicDataView from "../dynamic/DynamicDataView";
import { decodeAttribute } from "../parser/parserAbsolute";
import FilePicker from "../widgets/FilePicker";
import IconDialog from "../widgets/IconDialog";
import ImagePanel from "../widgets/ImagePanel";
import VoiceTyper from "../widgets/VoiceTyper";

import { getFileURL } from "../_helper/file";
import { FilePreview } from "./FilePreviews";
import CodeEditor from "../widgets/CodeEditor";
import { BGRemover } from "../widgets/BGRemover";
import { parseImgURL } from "../_helper/image";
import { SmartCropper } from "../widgets/SmartCropper";

// const IconDialog = Loadable({
//     loader: () => import("../widgets/IconDialog"),
//     loading: LoadingDialog,
// });

export const rangeOption = (
	label,
	value,
	onChange,
	min = 0,
	max = 100,
	step = 1
) => {
	return (
		<div
			key={label}
			style={{ display: "flex", flexDirection: "row", gap: "5px" }}
		>
			<Form.Field style={{ flex: 1 }}>
				<label style={{ marginBottom: "10px" }}>{label}</label>
				<Range
					// step={1}
					min={min}
					max={value > max ? value : max}
					step={step}
					// max={max}
					values={[value]}
					onChange={(values) => {
						log.p("Range changed to " + value);
						onChange(values[0]);
					}}
					renderTrack={({ props, children }) => (
						<div
							{...props}
							style={{
								...props.style,
								height: "6px",
								width: "100%",
								// backgroundColor: "#5f9de8",
								backgroundColor: "var(--q_color_primary)",
								borderRadius: "2px",
							}}
						>
							{children}
						</div>
					)}
					renderThumb={({ props }) => (
						<div
							{...props}
							style={{
								...props.style,
								height: "15px",
								width: "15px",
								backgroundColor: "white",
								border: "1px solid black",
								borderRadius: "2px",
							}}
						/>
					)}
				/>
			</Form.Field>

			<Form.Input
				style={{
					width: "70px",
					marginTop: "8px",
					paddingLeft: "1px",
				}}
				type="number"
				value={value}
				onChange={(eve) => onChange(parseInt(eve.target.value))}
			/>
		</div>
	);
};

export const multiRangeOption = (
	label,
	values,
	onChange,
	min = 0,
	max = 100,
	step = 1
) => {
	return (
		<Fragment key={label}>
			<Form.Field style={{ flex: 1 }}>
				<label style={{ marginBottom: "10px" }}>{label}</label>
				<Range
					// step={1}
					min={min}
					max={max}
					step={step}
					// max={max}
					values={values}
					onChange={(values) => {
						onChange(values);
					}}
					renderTrack={({ props, children }) => (
						<div
							{...props}
							style={{
								...props.style,
								height: "6px",
								width: "100%",
								// backgroundColor: "#5f9de8",
								backgroundColor: "var(--q_color_primary)",
								borderRadius: "2px",
							}}
						>
							{children}
						</div>
					)}
					renderThumb={({ props }) => (
						<div
							{...props}
							style={{
								...props.style,
								height: "15px",
								width: "15px",
								backgroundColor: "white",
								border: "1px solid black",
								borderRadius: "2px",
							}}
						/>
					)}
				/>
			</Form.Field>

			<div key={label}
				style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginTop: "-15px" }}>
				<Form.Input
					style={{
						width: "70px",
						marginTop: "8px",
						paddingLeft: "1px",
					}}
					type="number"
					value={values?.[0] || 0}
					onChange={(eve) => {
						const new_val = parseInt(eve.target.value);
						if (new_val) {
							onChange([new_val, values?.[1]])
						}
					}}
				/>
				<Form.Input
					style={{
						width: "70px",
						marginTop: "8px",
						paddingLeft: "1px",
					}}
					type="number"
					value={values?.[1] || 100}
					onChange={(eve) => {
						const new_val = parseInt(eve.target.value);
						if (new_val) {
							onChange([values?.[0], new_val])
						}
					}}
				/>
			</div>
		</Fragment>
	);
};


export function ControlledInput({ value, onChange, placeholder = "" }) {
	const [cursor, setCursor] = useState(null);
	const ref = useRef(null);

	useEffect(() => {
		const input = ref.current;
		if (input) input.setSelectionRange(cursor, cursor);
	}, [ref, cursor, value]);

	const handleChange = (e) => {
		setCursor(e.target.selectionStart);
		onChange && onChange(e);
	};

	return (
		<input
			ref={ref}
			value={value}
			onChange={handleChange}
			placeholder={placeholder}
		/>
	);
}

export function ControlledTextArea({ value, onChange }) {
	const [cursor, setCursor] = useState(null);
	const ref = useRef(null);

	useEffect(() => {
		const input = ref.current;
		if (input) input.setSelectionRange(cursor, cursor);
	}, [ref, cursor, value]);

	const handleChange = (e) => {
		setCursor(e.target.selectionStart);
		onChange && onChange(e);
	};

	return <textarea ref={ref} value={value} onChange={handleChange} />;
}

export const textOption = (
	label,
	value,
	onChange,
	type = "text",
	placeholder = "",
	auto_configure = false
) => {
	return (
		<Form.Field>
			{/* {label && <label>{label}</label>} */}
			<label>
				<div
					style={{
						display: "flex",
						flexDirection: "row",
						justifyContent: "space-between",
						cursor: "pointer",
					}}
				>
					<span>{label}</span>
					<DynamicDataOption
						value={value}
						onChange={onChange}
						ftype="string"
						auto_configure={auto_configure}
					/>
				</div>
			</label>
			{isString(value) && (
				<ControlledInput
					value={value}
					onChange={(e) => onChange(e.target.value)}
					placeholder={placeholder}
				/>
			)}
			{!isString(value) && (
				<DynamicDataView
					value={value}
					reset={() => {
						onChange("");
					}}
				/>
			)}
			{/* <Input
                type={type}
                value={value}
                onChange={(eve) => onChange(eve.target.value)}
            /> */}
		</Form.Field>
	);
};

export const numberOption = (
	label,
	value,
	onChange,
	min = 0,
	max = 100,
	placeholder = ""
) => {
	return (
		<Form.Field>
			{label && <label>{label}</label>}
			<input
				value={value}
				onChange={(e) => onChange(e.target.value)}
				placeholder={placeholder}
				type="number"
				min={min}
				max={max}
			/>
		</Form.Field>
	);
};

// export const longTextOption = (label, value, onChange) => {
// 	return (
// 		<Form.Field key={label}>
// 			<label>{label}</label>
// 			<TextArea
// 				value={value}
// 				onChange={(eve) => onChange(eve.target.value)}
// 			/>
// 		</Form.Field>
// 	);
// };

const isString = (value) => {
	return typeof value === "string" || value instanceof String;
};

export function DebounceInput({ value, setValue, t = 2000 }) {
	const [val, setVal] = useState(value);

	useEffect(() => {
		const setData = setTimeout(() => {
			setValue(val);
		}, t);

		return () => clearTimeout(setData);
	}, [val]);

	return (
		<TextArea value={val} onChange={(eve) => setVal(eve.target.value)} />
	);
}

export const textDebounceOption = (label, value, onChange) => {
	return (
		<React.Fragment key={label}>
			<Form.Field key={label}>
				<label>
					<div
						style={{
							display: "flex",
							flexDirection: "row",
							justifyContent: "space-between",
							cursor: "pointer",
						}}
					>
						<span>{label}</span>
						<DynamicDataOption
							value={value}
							onChange={onChange}
							ftype="string"
						/>
					</div>
				</label>
				{isString(value) && (
					<DebounceInput value={value} setValue={onChange} />
				)}
				{!isString(value) && (
					<DynamicDataView
						value={value}
						reset={() => {
							onChange("");
						}}
					/>
				)}
			</Form.Field>
		</React.Fragment>
	);
};

export const longTextOption = (
	label,
	value,
	onChange,
	auto_configure = false,
	show_before_after = true
) => {
	return (
		<React.Fragment key={label}>
			<Form.Field key={label}>
				<label>
					<div
						style={{
							display: "flex",
							flexDirection: "row",
							justifyContent: "space-between",
							cursor: "pointer",
						}}
					>
						<span>{label}</span>
						<DynamicDataOption
							value={value}
							onChange={onChange}
							ftype="string"
							auto_configure={auto_configure}
						/>
					</div>
				</label>
				{isString(value) && (
					// <TextArea
					//     value={value}
					//     onChange={(eve) => onChange(eve.target.value)}
					// />
					<ControlledTextArea
						value={value}
						onChange={(eve) => onChange(eve.target.value)}
					/>

				)}
				{isString(value) && <p style={{ color: "#999" }}># of characters: {value.length}</p>}
				{!isString(value) && (
					<DynamicDataView
						value={value}
						reset={() => {
							onChange("");
						}}
					/>
				)}
				{show_before_after && !isString(value) && (
					<div
						style={{
							display: "grid",
							gridTemplateColumns: "1fr 1fr",
							gap: "5px",
							paddingTop: "15px",
						}}
					>
						<Form.Field>
							<label>Text Before</label>
							<Input
								fluid
								value={value?.before}
								onChange={(e) =>
									onChange({
										...value,
										before: e.target.value,
									})
								}
							/>
						</Form.Field>
						<Form.Field>
							<label>Text After</label>
							<Input
								fluid
								value={value?.after}
								onChange={(e) =>
									onChange({
										...value,
										after: e.target.value,
									})
								}
							/>
						</Form.Field>
					</div>
				)}
			</Form.Field>
			{/* {isString(value) && (
                <VoiceTyper
                    content={value}
                    setContent={(newVal) => onChange(newVal)}
                />
            )} */}
		</React.Fragment>
	);
};

export const longTextOption_bk = (label, value, onChange) => {
	return (
		<React.Fragment key={label}>
			<VoiceTyper
				content={value}
				setContent={(newVal) => onChange(newVal)}
			/>
			<Form.Field key={label}>
				<label>{label}</label>
				<TextArea
					value={value}
					onChange={(eve) => onChange(eve.target.value)}
				/>
			</Form.Field>
		</React.Fragment>
	);
};

export const codeOption = (label, value, onChange, lang) => {
	return (
		<Form.Field>
			<label>{label}</label>
			<CodeEditor value={value} setValue={onChange} lang={lang} />
		</Form.Field>
	);
};

export const booleanOption = (label, value, onChange) => {
	return (
		<Form.Field key={label}>
			<Form.Checkbox
				label={label}
				checked={value}
				onChange={() => onChange(!value)}
			/>
		</Form.Field>
	);
};

export const ColorConfigPicker = ({ active, value, setValue }) => {
	// const gsc = useRecoilValue(gstyleConfigState);
	const gsc = useRecoilValue(themeColorState);

	return (
		<Dropdown
			icon={null}
			trigger={
				<Button
					icon="circle"
					style={{
						color: "#7f7f7f",
						border: "1px solid #f1f1f1",
						...(active ? { color: value } : {}),
					}}
				/>
			}
		>
			<Dropdown.Menu>
				<Dropdown.Header icon="globe" content="Global Colors" />
				<Dropdown.Menu scrolling>
					{Object.keys(gsc).map((item) => (
						<Dropdown.Item
							key={item}
							text={startCase(item)}
							value={item}
							label={
								<Label
									circular={true}
									empty={true}
									style={{
										backgroundColor: gsc[item],
									}}
								/>
							}
							onClick={() =>
								setValue({
									type: "config",
									value: item,
								})
							}
						/>
					))}
				</Dropdown.Menu>
			</Dropdown.Menu>
		</Dropdown>
	);
};

export function QColorPicker({ color, setColor }) {
	const [picker, setPicker] = useState("spectrum");

	const itemStyle = {
		padding: "3px",
		backgroundColor: "#ddd",
		textAlign: "center",
		cursor: "pointer",
	};

	const selectedItemStyle = {
		padding: "3px",
		backgroundColor: "royalblue",
		textAlign: "center",
		color: "white",
		cursor: "pointer",
	};

	return (
		<Fragment>
			{picker === "slider" && (
				<div
					style={{
						width: "250px",
					}}
				>
					<SliderPicker
						color={color}
						onChangeComplete={(color) => {
							setColor(color);
							// setColor(
							//     `rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})`
							// );
						}}
					/>
				</div>
			)}
			{picker === "grid" && (
				<SwatchesPicker
					color={color}
					onChangeComplete={(color) => {
						setColor(color);
						// setColor(
						//     `rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})`
						// );
					}}
				/>
			)}
			{picker === "spectrum" && (
				<SketchPicker
					color={color}
					onChangeComplete={(color) => {
						setColor(color);
						// setColor(
						//     `rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})`
						// );
					}}
				/>
			)}
			<div
				style={{
					display: "grid",
					gridTemplateColumns: "1fr 1fr 1fr",
					paddingTop: "10px",
					borderRadius: "3px",
					overflow: "hidden",
				}}
			>
				<div
					style={
						picker === "spectrum" ? selectedItemStyle : itemStyle
					}
					onClick={() => setPicker("spectrum")}
				>
					Spectrum
				</div>
				<div
					style={picker === "grid" ? selectedItemStyle : itemStyle}
					onClick={() => setPicker("grid")}
				>
					Grid
				</div>
				<div
					style={picker === "slider" ? selectedItemStyle : itemStyle}
					onClick={() => setPicker("slider")}
				>
					Slider
				</div>
			</div>
		</Fragment>
	);
}

export const colorOption = (label, value, onChange, gs, format = "rgba") => {
	let val = value;
	const isObj = typeof value === "object";
	if (isObj) {
		val = decodeAttribute(value, gs);
	}

	return (
		<Fragment>
			<Form.Field key={label}>
				{/* <label>{key}</label> */}
				{label && <label>{label}</label>}
				<div
					style={{
						display: "flex",
						flexDirection: "row",
						gap: "2px",
					}}
				>
					<Popup
						style={{
							zIndex: 11100,
						}}
						trigger={
							<Button
								icon="tint"
								// primary={!isObj}
								style={{
									// color: "#7f7f7f",
									border: "1px solid #f1f1f1",
									...(isObj ? {} : { color: val }),
								}}
							/>
						}
						eventsEnabled={true}
						on="click"
					>
						<QColorPicker
							color={val}
							// setColor={onChange}
							setColor={(color) => {
								if (format === "rgba") {
									onChange(
										`rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})`
									);
								} else {
									onChange(color.hex);
								}
							}}
						/>
					</Popup>
				</div>
			</Form.Field>
		</Fragment>
	);
};

export const selectOption = (
	label,
	value,
	setValue,
	optionList,
	placeholder = ""
) => {
	// const optionList = [
	// 	[`Arial`, `Arial, Helvetica, sans-serif`],
	// 	[`Comic Sans MS`, `"Comic Sans MS", cursive, sans-serif`],
	// 	[`Verdana`, `Verdana, Geneva, sans-serif`],
	// 	[`Lucida Console`, `"Lucida Console", Monaco, monospace`],
	// ];

	const options = optionList.map((item) => {
		return {
			key: item[0],
			text: item[0],
			value: item[1],
		};
	});

	const handleChange = (e, { value }) => setValue(value);

	return (
		<Form.Field key={label}>
			{label && <label>{label}</label>}
			<Dropdown
				placeholder={label || placeholder}
				options={options}
				selection
				value={value}
				onChange={handleChange}
			/>
		</Form.Field>
	);
};

export function InternalListOption({
	label,
	value,
	setValue,
	new_item = "",
	item_render = (item, handleChange, index) => (
		<input
			value={item}
			onChange={(eve) => handleChange(index, eve.target.value)}
		/>
	),
}) {
	const total = value.length;
	const last_option = value[total - 1];
	if (last_option?.value !== new_item?.value) {
		// value.push(new_item);
		log.d(last_option, "In Last Option");
		const newVal = Array.from(value);
		newVal.push(new_item);
		setValue(newVal);
	}

	const handleChange = (index, val) => {
		const newVal = Array.from(value);
		newVal[index] = val;
		if (index === total - 1) {
			newVal.push(new_item);
		}

		setValue(newVal);
	};

	const deleteItem = (index) => {
		const newVal = Array.from(value);
		newVal.splice(index, 1);
		setValue(newVal);
	};

	return (
		<Form.Field key={label}>
			<label>{label}</label>
			{value.map((item, index) => {
				return (
					<Form.Input placeholder="Item1" key={index}>
						{item_render(item, handleChange, index)}
						{/* <InputRender
                            item={item}
                            handleChange={handleChange}
                            index={index}
                        /> */}
						<Button
							icon="delete"
							style={{
								marginLeft: "5px",
								opacity: index === value.length - 1 ? 0 : 1,
							}}
							onClick={() => deleteItem(index)}
						/>
					</Form.Input>
				);
			})}
		</Form.Field>
	);
}

export const listOption = (label, value, setValue) => {
	return (
		<InternalListOption
			label={label}
			value={value}
			setValue={setValue}
			new_item=""
		/>
	);
};

export const dynamicSelectOption = (
	label,
	label2,
	value,
	value2,
	setValue,
	setValue2,
	optionList1,
	optionList2
) => {
	const options = optionList1.map((item) => {
		return {
			key: item[0],
			text: item[0],
			value: item[1],
		};
	});

	const options2 = optionList2[value].map((item) => {
		return {
			key: item[0],
			text: item[0],
			value: item[1],
		};
	});

	return (
		<Fragment>
			<Form.Field key={label}>
				<label>{label}</label>
				<Dropdown
					placeholder={label}
					options={options}
					selection
					value={value}
					onChange={(e, { value }) => setValue(value)}
				/>
			</Form.Field>
			<Form.Field key={label2}>
				<label>{label2}</label>
				<Dropdown
					placeholder={label2}
					options={options2}
					selection
					value={value2}
					onChange={(e, { value }) => setValue2(value)}
				/>
			</Form.Field>
		</Fragment>
	);
};

export const optionRowIcon = (label, value, onChange, options) => {
	return (
		<Form.Field key={label}>
			<label>{label}</label>
			<div
				style={{
					display: "grid",
					gridTemplateColumns: "repeat(5, 40px)",
					rowGap: "5px",
					columnGap: "5px",
				}}
			>
				{options.map((item) => (
					<Button
						key={item[0]}
						style={{
							margin: 0,
						}}
						icon
						onClick={() => {
							onChange(item[0]);
						}}
						primary={value === item[0]}
					>
						<Icon name={item[1]} />
					</Button>
				))}
			</div>
		</Form.Field>
	);
};

export const optionRowNumber = (label, value, onChange, options) => {
	return (
		<Form.Field key={label}>
			<label>{label}</label>
			<div
				style={{
					display: "grid",
					gridTemplateColumns: "repeat(5, 40px)",
					rowGap: "5px",
					columnGap: "5px",
				}}
			>
				{options.map((item) => (
					<Button
						key={item[0]}
						style={{
							margin: 0,
						}}
						icon
						onClick={() => {
							onChange(item[0]);
						}}
						primary={value === item[0]}
					>
						{item[1]}
					</Button>
				))}
			</div>
		</Form.Field>
	);
};

export const fileOption = (filetype, label, value, setValue) => {
	return (
		<Form.Field key={label}>
			<label>{label}</label>
			{value?.params?.filename_download && (
				<div
					style={{
						paddingBottom: "10px",
					}}
				>
					<FilePreview item={value.params} />
				</div>
			)}
			<FilePicker
				type={filetype}
				onFile={(file) => {
					// if (file?.type?.startsWith(filetype)) {
					setValue({
						params: file,
						value: getFileURL(file.id),
					});
					// }
				}}
			/>
		</Form.Field>
	);
};

//type= img, svg
export const imgSrcOption = (
	label,
	value,
	setValue,
	trigger = null,
	type = "img",
	setData
) => {
	// log.d(value, "Value in imgSRcOption");
	return (
		<Form.Field key={label}>
			{/* <label>{label}</label> */}
			{trigger ? (
				""
			) : (
				<label>
					<div
						style={{
							display: "flex",
							flexDirection: "row",
							justifyContent: "space-between",
							cursor: "pointer",
						}}
					>
						<span>{label}</span>
						<DynamicDataOption
							value={value}
							onChange={setValue}
							ftype="image"
						/>
					</div>
				</label>
			)}
			{("params" in value || type === "shape") && (
				<ImagePanel
					type={type}
					button={
						trigger ||
						(type === "shape" ? (
							"params" in value ? (
								<svg
									xmlns="http://www.w3.org/2000/svg"
									// viewBox="0 0 346.6 261.4"
									viewBox={`0 0 ${value?.params?.w} ${value?.params?.h}`}
									style={{
										width: "100px",
										height: "100%",
										fill: "#e1effa",
									}}
								>
									<title>Asset 97</title>
									<path
										d={value?.params?.d}
										fillRule="nonzero"
									/>
								</svg>
							) : (
								<Button>Select Mask</Button>
							)
						) : (
							<div className={styles.img_wrapper}>
								<img
									src={parseImgURL(value)}
									style={{
										width: "100%",
									}}
									alt="Logo"
								/>
								<div className={styles.img_overlay}>
									<Icon name="pencil" />
								</div>
							</div>
						))
					}
					onPhoto={(photo) => {
						setValue({
							params: photo,
							value: photo.full,
						});
					}}
				/>
			)}
			{type === "img" && !trigger && "params" in value && !value?.params?.bg_removed && !value?.params?.smart_cropped && (
				<BGRemover
					fileobj={value?.params}
					onSuccess={(newPhoto) => {
						setValue({
							params: newPhoto,
							value: newPhoto.full,
						});
						setData(old_data => {
							return {
								...old_data,
								input: {
									...old_data.input,
									imageFit: "contain"
								}
							}
						})
					}
					}
				/>
			)}
			{type === "img" && !trigger && "params" in value && value?.params?.type === "image/png" && !value?.params?.smart_cropped && (
				<SmartCropper
					fileobj={value?.params}
					onSuccess={(newPhoto) => {
						setValue({
							params: newPhoto,
							value: newPhoto.full,
						});
						setData(old_data => {
							return {
								...old_data,
								input: {
									...old_data.input,
									imageFit: "contain"
								}
							}
						})
					}
					}
				/>
			)}
			{type === "svg" && value?.colors?.length > 0 && (
				<div
					style={{
						display: "flex",
						flexDirection: "row",
						gap: "5px",
						flexWrap: "wrap",
					}}
				>
					{value.colors.map((col, index) => {
						return colorOption(
							null,
							col,
							(newCol) => {
								setValue(
									produce(value, (draft) => {
										draft.colors[index] = newCol;
									})
								);
							},
							{},
							"hex"
						);
					})}
				</div>
			)}
			{!("params" in value) && type !== "shape" && (
				<DynamicDataView
					value={value}
					reset={() => {
						setValue({
							params: null,
							value: process.env.PUBLIC_URL + "img_pp.png",
						});
					}}
				/>
			)}
			{type === "shape" && "params" in value && (
				<Button
					onClick={() => {
						setValue(null);
					}}
				>
					Remove Mask
				</Button>
			)}
		</Form.Field>
	);
};

function isValidURL(string) {
	try {
		new URL(string);
		return true;
	} catch (_) {
		return false;
	}
}

function extractURL(value) {
	// Check if the value is a URL
	if (isValidURL(value)) {
		return value;
	}

	// If not, try to extract an URL from an iframe
	const regex = /<iframe[^>]* src="([^"]*)"[^>]*>/;
	const match = regex.exec(value);

	if (match) {
		const url = match[1];

		if (isValidURL(url)) {
			return url;
		}
	}

	// throw new Error('No valid URL found');
	return false;
}

export const urlOption = (label, value, setValue) => {
	return (
		<Fragment>
			<Form.Field>
				<label>{label}</label>
				<input
					type="text"
					placeholder="Enter URL"
					value={value.params}
					onChange={(eve) => {
						const new_val = eve.target.value;
						const valid_url = extractURL(new_val);
						if (valid_url) {
							setValue({
								params: new_val,
								value: valid_url,
							});
						} else {
							setValue({
								params: new_val,
								value: false,
							});
						}
					}}
				/>
				<div
					style={{
						paddingTop: "5px",
						color: value.value ? "green" : "red",
					}}
				>
					{value.value
						? "Valid URL is valid."
						: "Valid URL not found."}
				</div>
				{/* <p>Extracted ULR: {value.value}</p> */}
			</Form.Field>
		</Fragment>
	);
};

export const ytSourceOption = (label, value, setValue) => {
	function youtube_parser(url) {
		if (url.includes("shorts")) {
			url = url.replace(new RegExp("shorts", "g"), "embed");
		}
		var regExp =
			/^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;

		// var regExp =
		//     /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?)|(shorts\/))\??v?=?([^#&?]*).*/;
		var match = url.match(regExp);
		return match && match[7].length === 11 ? match[7] : false;
	}

	return (
		<Fragment>
			<Form.Field>
				<label>{label}</label>
				<input
					type="text"
					placeholder="Youtube Link"
					value={value.params}
					onChange={(eve) => {
						const video_id = youtube_parser(eve.target.value);
						if (video_id) {
							setValue({
								...value,
								params: eve.target.value,
								value: youtube_parser(eve.target.value),
							});
						} else {
							setValue({
								...value,
								params: eve.target.value,
								value: false,
							});
						}
					}}
				/>
				<div
					style={{
						paddingTop: "5px",
						color: value.value ? "green" : "red",
					}}
				>
					{value.value ? "Link is valid." : "Link is not valid."}
				</div>
			</Form.Field>
		</Fragment>
	);
};

export const rangeWithDefault = (
	label,
	customLabel,
	value,
	onChange,
	min = 0,
	max = 100,
	step = 1,
	autoValue,
	defaultCustom,
	combiner
) => {
	const onOptionChange = (newVal) => {
		if (newVal === "auto") {
			onChange({
				type: "auto",
				value: autoValue,
			});
		} else {
			onChange({
				type: "custom",
				params: defaultCustom,
				value: combiner(defaultCustom),
			});
		}
	};
	return (
		<Fragment>
			{optionRowIcon(startCase(label), value.type, onOptionChange, [
				["auto", "home"],
				["custom", "ellipsis horizontal"],
			])}
			{value.type === "custom" &&
				rangeOption(
					// "Custom Letter Spacing",
					customLabel,
					value.params,
					(newVal) =>
						onChange({
							type: "custom",
							params: newVal,
							value: combiner(newVal),
						}),
					min,
					max,
					step
				)}
		</Fragment>
	);
};

export const iconOption = (label, value, setValue) => {
	return (
		<Form.Field key={label}>
			<label>{label}</label>
			<IconDialog
				button={
					<span
						css={{
							fontSize: "30px",
							color: "black",
							backgroundColor: "#E0E1E2",
							width: "60px",
							height: "60px",
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
							borderRadius: "5px",
							":hover": {
								cursor: "pointer",
							},
						}}
					>
						<i className={value.value}></i>
					</span>
				}
				setIcon={(newIcon) => {
					setValue(newIcon);
				}}
			/>
		</Form.Field>
	);
};

// combiner= a function to convert from params to value
export const fourSide = (
	label,
	value,
	setValue,
	combiner,
	min = 0,
	max = 100,
	corner = false,
	step = 1
) => {
	const params = value.params;
	const sliderValue = value.type === "single" ? params : params[params.cside];

	// if (sliderValue > 70) {
	// 	max = sliderValue + Math.floor(sliderValue / 40);
	// }

	// if (sliderValue > max) {
	// 	max = sliderValue;
	// }

	const onChange = (newVal) => {
		log.p(`Changing value via input:: ${newVal}`);
		if (value.type === "single") {
			setValue({
				type: "single",
				params: newVal,
				value: `${newVal}px`,
			});
		} else {
			const combinedValue = combiner({
				...params,
				[params.cside]: newVal,
			});
			log.d(combinedValue, "Multiple paddign vlaue");
			setValue({
				type: "multiple",
				params: {
					...params,
					[params.cside]: newVal,
				},
				// value: combiner(params),
				value: combinedValue,
			});
		}
	};

	const onSideChange = (side) => {
		if (value.type === "single") {
			setValue({
				...value,
				type: "multiple",
				params: {
					cside: side,
					top: value.params,
					right: value.params,
					bottom: value.params,
					left: value.params,
				},
			});
		} else {
			setValue({
				...value,
				type: "multiple",
				params: { ...params, cside: side },
			});
		}
	};
	return (
		<Form.Field key={label}>
			<label>{label}</label>
			<div
				style={{
					display: "grid",
					gridTemplateColumns: "repeat(5, 40px)",
					rowGap: "5px",
					columnGap: "5px",
				}}
			>
				{[
					["top", "arrow up"],
					["right", "arrow right"],
					["bottom", "arrow down"],
					["left", "arrow left"],
				].map((item, index) => {
					return (
						<Button
							key={index}
							style={{
								margin: 0,
							}}
							icon
							onClick={() => {
								onSideChange(item[0]);
							}}
							primary={params.cside === item[0]}
						>
							<Icon
								name={item[1]}
								style={
									corner
										? { transform: "rotate(-45deg)" }
										: {}
								}
							/>
						</Button>
					);
				})}

				<Button
					style={{
						margin: 0,
					}}
					icon
					onClick={() => {
						const temp =
							value.type === "single"
								? params
								: parseInt(
									(params.left +
										params.right +
										params.top +
										params.bottom) /
									4
								);
						setValue({
							type: "single",
							params: temp,
							value: `${temp}px`,
						});
					}}
					primary={value.type === "single"}
				>
					<Icon
						name={value.type === "single" ? "lock" : "lock open"}
					/>
				</Button>
			</div>
			<div style={{ display: "flex", flexDirection: "row", gap: "5px" }}>
				<Form.Field style={{ flex: 1 }}>
					<label style={{ marginBottom: "25px" }}></label>
					<Range
						step={step}
						// min={min < sliderValue ? min : sliderValue - 50}
						// max={max > sliderValue ? max : sliderValue + 50}
						min={min > sliderValue ? sliderValue : min}
						max={max < sliderValue ? sliderValue : max}
						values={[sliderValue]}
						onChange={(values) => {
							log.p("Range changed to " + value);
							onChange(values[0]);
						}}
						// onFinalChange={(values) => {
						// 	log.d(values, "New Range Value");
						// 	max = values[0] + 50;
						// 	onChange(values[0]);
						// }}
						renderTrack={({ props, children }) => (
							<div
								{...props}
								style={{
									...props.style,
									height: "6px",
									width: "100%",
									// backgroundColor: "#5f9de8",
									backgroundColor: "var(--q_color_primary)",
									borderRadius: "2px",
								}}
							>
								{children}
							</div>
						)}
						renderThumb={({ props }) => (
							<div
								{...props}
								style={{
									...props.style,
									height: "15px",
									width: "15px",
									backgroundColor: "white",
									border: "1px solid black",
									borderRadius: "2px",
								}}
							/>
						)}
					/>
				</Form.Field>

				<Form.Input
					style={{
						width: "70px",
						marginTop: "8px",
						paddingLeft: "1px",
						paddingRight: "-10px",
					}}
					type="number"
					value={sliderValue}
					onChange={(eve) => onChange(parseInt(eve.target.value))}
				/>
			</div>
		</Form.Field>
	);
};

export const fourSide2 = (
	label,
	value,
	setValue,
	combiner,
	min = 0,
	max = 100
) => {
	const params = value.params;
	const sliderValue = value.type === "single" ? params : params[params.cside];

	const onChange = (newVal) => {
		log.p("Changing value");
		if (value.type === "single") {
			setValue({
				type: "single",
				params: newVal,
				value: `${newVal}px`,
			});
		} else {
			const combinedValue = combiner(newVal);
			log.p(combinedValue, "Multiple paddign vlaue");
			setValue({
				type: "multiple",
				params: {
					...params,
					[params.cside]: newVal,
				},
				value: combinedValue,
			});
		}
	};

	const onSideChange = (side) => {
		if (value.type === "single") {
			setValue({
				...value,
				type: "multiple",
				params: {
					cside: side,
					top: value.params,
					right: value.params,
					bottom: value.params,
					left: value.params,
				},
			});
		} else {
			setValue({
				...value,
				type: "multiple",
				params: { ...params, cside: side },
			});
		}
	};
	return (
		<Form.Field key={label}>
			<label>{label}</label>
			<div
				style={{
					display: "grid",
					gridTemplateColumns: "repeat(5, 40px)",
					rowGap: "5px",
					columnGap: "5px",
				}}
			>
				{[
					["top", "arrow up"],
					["right", "arrow right"],
					["bottom", "arrow down"],
					["left", "arrow left"],
				].map((item, index) => {
					return (
						<Button
							key={index}
							style={{
								margin: 0,
							}}
							icon
							onClick={() => {
								onSideChange(item[0]);
							}}
							primary={params.cside === item[0]}
						>
							<Icon name={item[1]} />
						</Button>
					);
				})}

				<Button
					style={{
						margin: 0,
					}}
					icon
					onClick={() => {
						const temp =
							value.type === "single"
								? params
								: parseInt(
									(params.left +
										params.right +
										params.top +
										params.bottom) /
									4
								);
						setValue({
							type: "single",
							params: temp,
							value: `${temp}px`,
						});
					}}
					primary={value.type === "single"}
				>
					<Icon
						name={value.type === "single" ? "lock" : "lock open"}
					/>
				</Button>
			</div>
			<div style={{ display: "flex", flexDirection: "row", gap: "5px" }}>
				<Form.Field style={{ flex: 1 }}>
					<label style={{ marginBottom: "25px" }}></label>
					<Range
						step={1}
						min={min}
						max={max}
						// max={max}
						values={[sliderValue]}
						onChange={(values) => {
							log.p("Range changed to " + value);
							onChange(values[0]);
						}}
						renderTrack={({ props, children }) => (
							<div
								{...props}
								style={{
									...props.style,
									height: "6px",
									width: "100%",
									backgroundColor: "#5f9de8",
									borderRadius: "2px",
								}}
							>
								{children}
							</div>
						)}
						renderThumb={({ props }) => (
							<div
								{...props}
								style={{
									...props.style,
									height: "15px",
									width: "15px",
									backgroundColor: "white",
									border: "1px solid black",
									borderRadius: "2px",
								}}
							/>
						)}
					/>
				</Form.Field>

				<Form.Input
					style={{
						width: "70px",
						marginTop: "8px",
						paddingLeft: "1px",
					}}
					type="number"
					value={sliderValue}
					onChange={(eve) => onChange(parseInt(eve.target.value))}
				/>
			</div>
		</Form.Field>
	);
};
