import React, { useState } from "react";
import StyleButtonAlone from "../widgets/slateeditor/inputs/StyleButtonAlone";
import { Button, Icon } from "../widgets/slateeditor/components"
import { Popup } from "semantic-ui-react";
import { QColorPicker } from "./SimpleOptions"


function TextEditBar({ bold, setBold, italic, setItalic, underline, setUnderline, color, setColor, bg_color, setBGColor }) {
	const [engaged, setEngaged] = useState(false);

	return <div style={{
		display: 'flex',
		flexDirection: "row",
		gap: "15px",
		alignItems: "center",
		paddingTop: "10px"
	}}>
		<Button
			active={bold}
			onMouseDown={setBold}
		>
			<Icon>fas fa-bold</Icon>
		</Button>
		<Button
			active={italic}
			onMouseDown={setItalic}
		>
			<Icon>fas fa-italic</Icon>
		</Button>
		<Button
			active={underline}
			onMouseDown={setUnderline}
		>
			<Icon>fas fa-underline</Icon>
		</Button>
		<div style={{ width: "10px" }}></div>


		<StyleButton
			icon='fas fa-font'
			value={color}
			onChange={setColor}
		/>
		<StyleButton
			icon='fas fa-fill-drip'
			value={bg_color}
			onChange={setBGColor}
		/>
	</div>
}

export default TextEditBar;




const StyleButton = ({
	icon,
	onChange = () => { },
	value,
	isActive = false,
	format = "rgba"
}) => {
	const [col, setCol] = useState("black");

	return (
		<Popup
			style={{
				zIndex: 11100,
			}}
			trigger={
				<Button active={isActive}>
					<Icon>{icon}</Icon>
					<div
						style={{
							width: "100%",
							height: "3px",
							backgroundColor: value,
						}}
					></div>
				</Button>
			}
			eventsEnabled={true}
			on="click"
		// onClose={onClose}
		>
			<QColorPicker
				color={value}
				// setColor={onChange}
				setColor={(color) => {
					let new_col = null;
					if (format === "rgba") {
						new_col = `rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})`;
						onChange(new_col);
					} else {
						new_col = color.hex;
						onChange(new_col);
					}
				}}
			/>
		</Popup>
	);
};
